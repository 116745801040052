import { withExtTenantIdRequest } from '@/utils/request';

// 推品清单-我的清单列表
export function listForPc(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorRecommendPlan/listForPc`,
    method: 'post',
    data
  });
}

// 推品清单-我的清单列表-批量删除我的清单
export function deleteByIds(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorRecommendPlan/deleteByIds`,
    method: 'post',
    data
  });
}

// 推品清单-详情
export function listDetailForPc(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorRecommendPlan/listDetailForPc`,
    method: 'post',
    data
  });
}

// 推品清单-详情-加入购物车
export function addRecommendPlanToCart(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantShoppingCart/addRecommendPlanToCart`,
    method: 'post',
    data
  });
}

// 小程序/商家PC端--推品清单-清单结算
export function recommendOrderCalculatePayment(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/recommendOrder/calculatePayment`,
    method: 'post',
    data
  });
}

// 【后台】小程序/商家PC端--推品清单-创建订单
export function recommendOrderCreate(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/recommendOrder/create`,
    method: 'post',
    data
  });
}

// 下单获取订单主体详情
export function getOrderContractInfo(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorContractInfo/getOrderContractInfo`,
    method: 'post',
    data
  });
}
<template>
  <div class="car" id="car">
    <div class="table">
      <ul class="table__top">
        <li class="table__top--check">
          <el-checkbox v-model="allchecked" @change="allcheckedChange"></el-checkbox>
        </li>
        <li class="table__top--name">清单名称</li>
        <li class="table__top--level">商品种类</li>
        <li class="table__top--price">件数</li>
        <li class="table__top--quantity">金额</li>
        <li class="table__top--allPrice">状态</li>
        <li class="table__top--operation">操作</li>
      </ul>
      <!-- 水羊大贸商品 -->
      <div>
        <ul class="table__content">
          <li v-for="(i, index) in list" :key="i.id" class="table__content--item">
            <div class="table__content--check">
              <el-checkbox
                v-model="i.isChecked"
                class="table__content--checkBox"
                @change="checkChange"
              ></el-checkbox>
            </div>
            <div class="table__content--name">
              <oss-image :src="i.imgUrl" class="table__content--nameImg" />
              <p class="table__content--nameName table__content--content">{{ i.recommendName }}</p>
            </div>
            <div class="table__content--level table__content--content">
              {{ i.commodityCount }}种商品
            </div>
            <div class="table__content--price table__content--content">
              {{ i.commodityQuantity }}件
            </div>
            <div class="table__content--quantity table__content--content">
              ¥{{ i.commodityAmount }}
            </div>
            <div
              class="table__content--allPrice table__content--content"
              :style="{ color: i.status === '1' ? '' : '#AB0033;' }"
            >
              {{ i.status === '0' ? '失效' : '有效' }}
            </div>
            <div class="table__content--operation table__content--content">
              <router-link :to="'/syoung/recommended-list/detail/' + i.recommendId">
                <el-button type="text" class="table__content--btn link">详情</el-button>
              </router-link>
              <el-button
                @click="handleDelete(i, index, 'normal')"
                type="text"
                class="table__content--btn"
                >删除</el-button
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div id="combined-position"></div>
    <div class="combined-div">
      <div class="combined">
        <div class="fl">
          <el-checkbox v-model="allchecked" @change="allcheckedChange">全选</el-checkbox>
          <el-button @click="allDel" type="text"
            ><i class="iconfont icon-shanchu"></i>批量删除</el-button
          >
        </div>
        <div class="fr">
          <div class="pagination">
            <el-pagination
              :current-page="pageNo"
              :disabled="listLoading"
              :page-size="pageSize"
              :page-sizes="[10, 20, 30, 40]"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { listForPc, deleteByIds } from '@/api/recommended-list/index';
import pick from 'lodash/pick';
export default {
  name: 'car',
  data() {
    return {
      data: {},
      listLoading: true,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      list: [],
      allchecked: false,
    };
  },
  computed: {},
  created() {
    this.fetchData();
  },
  mounted() {},
  destroyed() {},
  methods: {
    checkChange() {
      let flag = true;
      for (const i of this.list) {
        if (!i.isChecked) {
          flag = false;
          break;
        }
      }
      this.allchecked = flag;
      this.allcheckedFn();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      const listQuery = pick(this, ['pageNo', 'pageSize', 'data']);
      listForPc(listQuery)
        .then(response => {
          if (response.data && response.data.list) {
            this.list = response.data.list;
            this.total = response.data.total;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    // 全选
    allcheckedChange(val) {
      this.allchecked = val;
      this.list.forEach(i => {
        i.isChecked = val;
      });
    },
    // 单个删除商品
    handleDelete(val, index, type) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          const ids = [val.id];
          deleteByIds(ids).then(response => {
            this.list.splice(index, 1);
            this.$message({
              type: 'success',
              message: '删除成功！',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 批量删除商品
    allDel() {
      const ids = [];
      this.list.forEach(i => {
        if (i.isChecked) {
          ids.push(i.id);
        }
      });
      if (ids.length > 0) {
        this.$confirm('确认要批量删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            deleteByIds(ids).then(response => {
              this.allchecked = false;
              this.fetchData();
              this.$message({
                type: 'success',
                message: '删除成功！',
              });
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择需要删除的清单',
        });
      }
    },
  },
  filters: {},
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import './styles';
.table {
  text-align: center;
}
.table__content--content {
  height: 70px;
  line-height: 70px;
  font-size: 14px;
  font-weight: normal;
}
.table__content--name {
  width: 320px;
  display: flex;
  align-items: center;
  text-align: left;
  .table__content--nameImg {
    flex: none;
    margin-left: 15px;
  }
  .table__content--nameName {
    width: auto;
  }
}
.table__top--checkText {
  width: 80px;
}
.table__top--name {
  width: 320px;
}
.table__content--level {
  width: 170px;
}
.table__content--price {
  width: 160px;
}
.table__content--quantity {
  width: 158px;
}
.table__content--allPrice {
  width: 132px;
  color: #333;
}
.table__content--operation {
  width: 104px;
}
.link {
  color: #438eee;
  padding-right: 13px;
}
.table__content--btn {
  font-size: 14px;
}
.table__content--nameName {
  -webkit-line-clamp: 1;
}
</style>
